import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "./swiper-styles.css";
import "swiper/css";
import "swiper/css/pagination";

export const ImageSwiper = ({ images }) => {
  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      pagination={{
        clickable: true,
        dynamicBullets: true,
      }}
    >
      {images.map((imgSrc, index) => (
        <SwiperSlide key={index}>
          <img src={imgSrc} style={{ width: "100%", objectFit: "contain" }} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
