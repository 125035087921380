import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Link,
  TextField,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import Mailmoji from "../../assets/img/mail-emoji.png";

const styled = {
  "& label.Mui-focused": {
    color: "#62824D",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#62824D",
    },
  },
};

export const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      firstName,
      lastName,
      email,
      subject,
      message,
    };

    try {
      await axios.post("http://localhost:3001/send", data);
      setIsSubmitted(true);
    } catch (error) {
      console.error("There was an error sending the email", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        maxWidth: "1040px",
        "& .MuiTextField-root": { m: 1, width: "100%" },
        justifyContent: "center",
        alignItems: "center",
        m: "auto",
        p: { xs: "100px 0px", sm: "150px 0px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { xs: "80%", sm: "40%" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            width: { xs: "70%", sm: "80%" },
            p: { xs: "0px 0px 20px" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <img
            src={Mailmoji}
            style={{
              width: "100%",
              margin: "auto",
            }}
          />
        </Box>

        <Typography>
          email:{" "}
          <Link
            sx={{ color: "#262626", textDecorationColor: "#262626" }}
            href="mailto: ariana.detrez@gmail.com"
          >
            ariana.detrez@gmail.com
          </Link>
        </Typography>
      </Box>
      {isSubmitted ? (
        <Box
          sx={{
            width: { xs: "90%", sm: "60%" },
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            p: { xs: "25px 10px" },
          }}
        >
          <Typography sx={{ fontSize: "42px", fontWeight: "700" }}>
            Success💫
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              p: "15px 0px",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Thanks so much for reaching out! I will be in touch within 24-48
            hours and can’t wait to chat.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            width: { xs: "100%", sm: "60%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "25px 0px", sm: "0px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              sx={styled}
              required
              id="firstName"
              size="small"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              sx={styled}
              id="lastName"
              size="small"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              sx={styled}
              required
              id="email"
              size="small"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              sx={styled}
              required
              id="subject"
              size="small"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              sx={styled}
              id="message"
              label="Message"
              multiline
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%", p: "0px 0px 0px 10px" }}>
            <Button
              type="submit"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                color: "#FBFBFB",
                backgroundColor: "#62824D",
                mt: 2,
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
