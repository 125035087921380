import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import Bitmoji from "../../assets/img/Bitmoji.png";
import Down from "../../assets/img/DOWN.png";
import Hoptimist from "../../assets/img/Hoptimist_Home.png";
import Mindfuel from "../../assets/img/Mindfuel_Home.png";
import Feels from "../../assets/img/Feels_Home.png";
import { PictureLeft } from "./PictureLeft.js";
import { PictureRight } from "./PictureRight.js";

const apps = [
  {
    img: Hoptimist,
    appType: "SCHEDULING APP",
    appName: "Hoptimist",
    description:
      "Hoptimist is a scheduling app for beer gardens to allow locals to easily check upcoming events or reserve a table at their favorite beer garden.",
  },
  {
    img: Mindfuel,
    appType: "WELLNESS APP",
    appName: "Mindfuel",
    description:
      "Mindfuel focuses on showing habits is a game-like way to earn badges, have a personalized AI bot to encourage you along the way, and even compete with friends.",
  },
  {
    img: Feels,
    appType: "MENTAL HEALTH APP",
    appName: "Feels",
    description:
      "Feels focuses on sorting through one's feelings, one stepat a time. This tool is meant to eliminate the feeling of being overwhelmed and connect with one's emotions. Users can track their daily emotions, journal along the way, and reflect.",
  },
];

const Homepage = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
          pt: { xs: "14vh", sm: "16vh" },
          pb: "8vh",
          backgroundColor: "#FBFBFB",
        }}
      >
        <Box sx={{ width: { xs: "40%", sm: "30%", md: "10%" } }}>
          <img src={Bitmoji} style={{ width: "100%" }} alt="Bitmoji" />
        </Box>
        <Box sx={{ pt: "4vh", px: 2 }}>
          <Typography
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "700",
              fontSize: { xs: "32px", sm: "42px" },
              textAlign: "center",
              lineHeight: { xs: "40px", md: "51px" },
            }}
          >
            I craft user-friendly digital products
            <br />
            with a seamless experience.
          </Typography>
        </Box>
        <Box sx={{ pt: "11vh" }}>
          <ScrollLink
            to="projectSection"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <img src={Down} style={{ maxWidth: "70px" }} alt="Scroll down" />
          </ScrollLink>
        </Box>
      </Box>
      <Box
        id="projectSection"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FBFBFB",
          pb: { xs: "70px", md: "140px" },
        }}
      >
        <PictureLeft
          img={Hoptimist}
          appType={apps[0].appType}
          appName={apps[0].appName}
          description={apps[0].description}
          padding={{ xs: "50px", md: "150px" }}
          buttonText="VIEW CASE STUDY"
          buttonLink="/scheduling-app"
        />
        <PictureRight
          img={Mindfuel}
          appType={apps[1].appType}
          appName={apps[1].appName}
          description={apps[1].description}
          padding={{ xs: "50px", md: "150px" }}
          buttonText="CASE STUDY COMING SOON"
          isDisabled={true}
        />
        <PictureLeft
          img={Feels}
          appType={apps[2].appType}
          appName={apps[2].appName}
          description={apps[2].description}
          buttonText="CASE STUDY COMING SOON"
          isDisabled={true}
        />
      </Box>
    </>
  );
};

export default Homepage;
