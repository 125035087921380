import React from "react";
import { Box, Link, Typography } from "@mui/material";
import Insta from "../assets/img/instagram.png";
import Linkedin from "../assets/img/linkedin.png";

export const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "1440px",
        backgroundColor: "#FBFBFB",
        m: "auto",
        p: { xs: "0px 10px 25px 10px", md: "0px 0px 25px 5px" },
      }}
    >
      <Typography
        sx={{ color: "#262626", fontSize: { xs: "12px", sm: "16px" } }}
      >
        © 2023 Ariana Detrez, LLC
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link href="https://www.instagram.com/arianadetrez/" target="_blank">
          <Box sx={{ display: 'flex', maxWidth: { xs: "40px", sm: "50px" }, p: "0px 5px" }}>
            <img src={Insta} style={{ width: "100%", height: "auto" }} />
          </Box>
        </Link>
        <Link href="https://www.linkedin.com/in/aryvfi/" target="_blank">
          <Box sx={{ display: 'flex', maxWidth: { xs: "45px", sm: "55px" }, p: "0px 5px" }}>
            <img src={Linkedin} style={{ width: "100%", height: "auto" }} />
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
