import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import AboutPic from "../../assets/img/about_pic.png";

const capabilities = [
  "Brand Identity",
  "Illustration",
  "Art Direction",
  "User Research",
  "User Interface Design",
  "User Experience",
  "Site Maps",
  "Wireframing",
  "HTML / CSS",
];

const programs = [
  "Figma",
  "Sketch",
  "Illustrator",
  "Photoshop",
  "InDesign",
  "Procreate",
  "Microsoft 365",
  "Notion",
];

const passions = [
  "Diversity & Inclusion",
  "Therapeutic Art",
  "Mental Health",
  "Environment",
  "Impactful Design",
  "People",
];

const listStyle = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "90%", sm: "200px", md: "250px" },
  p: '0px 0px 30px'
};

export const About = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "1168px",
        height: "100%",
        m: "auto",
        p: "150px 0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          height: { xs: "100%", md: "433px" },
          m: "0em 0em 5em",
        }}
      >
        <Box
          order={{ xs: 2, sm: 1 }}
          sx={{
            width: { xs: "70%", sm: "50%", md: "30%" },
            height: { xs: "50", md: "100%" },
            m: { xs: "auto", md: "0" },
            p: { xs: "25px 0px 0px", sm: "0px 0px 0px 30px", md: "10px" },
          }}
        >
          <img
            src={AboutPic}
            style={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box
          order={{ xs: 1, sm: 2 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "70%" },
            p: { xs: "0px 30px", lg: "0px 50px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "36px", lg: "42px" },
              fontWeight: "700",
              p: "0px 0px 20px",
            }}
          >
            Hi, I’m Ariana Detrez! 👋🏼
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px", lg: "20px" },
              fontWeight: "500",
            }}
          >
            I’m a product designer with a keen eye for graphic design and
            illustration. With a passion for creating visually captivating
            experiences, I bring my artistic sensibilities to every project I
            undertakes. With a wealth of experience and a creative mindset,
            Ariana consistently delivers innovative and user-centered designs.
            <br />
            <br />
            When Ariana isn't immersed in the world of design, you'll find her
            spending time with her Aussie pups or roaming Disney.
            <br />
            <br />I believe in the power of imagination and infuses that,
            capturing the essence of wonder and delight in every project.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { sm: "space-evenly", md: "space-between" },
          alignItems: { xs: "center", sm: "flex-start" },
          width: "100%",
          m: { xs: "auto" },
        }}
      >
        <Box
          sx={listStyle}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#62824D",
              fontWeight: "600",
              p: "0px 0px 15px",
            }}
          >
            Capabilities
          </Typography>
          <Divider
            sx={{
              borderColor: "#62824D",
              borderBottomWidth: 2,
              width: "100%",
              mb: "1em",
            }}
          />
          <List>
            {capabilities.map((capability) => {
              return (
                <ListItem disablePadding>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "25px",
                    }}
                  >
                    {capability}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box
          sx={listStyle}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#62824D",
              fontWeight: "600",
              p: "0px 0px 15px",
            }}
          >
            Programs
          </Typography>
          <Divider
            sx={{
              borderColor: "#62824D",
              borderBottomWidth: 2,
              width: "100%",
              mb: "1em",
            }}
          />
          <List>
            {programs.map((program) => {
              return (
                <ListItem disablePadding>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "25px",
                    }}
                  >
                    {program}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={listStyle}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#62824D",
              fontWeight: "600",
              p: "0px 0px 15px",
            }}
          >
            Passions
          </Typography>
          <Divider
            sx={{
              borderColor: "#62824D",
              borderBottomWidth: 2,
              width: "100%",
              mb: "1em",
            }}
          />
          <List>
            {passions.map((passion) => {
              return (
                <ListItem disablePadding>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "25px",
                    }}
                  >
                    {passion}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
