import React, { createContext, useState, useEffect } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider.js";
import theme from "./theme/theme.js";
import { CssBaseline } from "@mui/material";
import {
  useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Homepage from "./components/Homepage/Homepage.js";
import { Navbar } from "./components/Navbar.js";
import { Footer } from "./components/Footer.js";
import { Contact } from "./components/Contact/Contact.js";
import { About } from "./components/About/About.js";
import { Graphic } from "./components/Graphic/Graphic.js";
import { Hoptimist } from "./components/CaseStudies/Hoptimist/Hoptimist.js";

export const ThemeContext = createContext();

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    document.documentElement.style.backgroundColor = darkMode
      ? "#292929"
      : "#FBFBFB";
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={darkMode ? theme.darkPalette : theme.lightPalette}>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/graphic" element={<Graphic />} />
            <Route path="/scheduling-app" element={<Hoptimist />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
