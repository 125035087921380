import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const PictureRight = ({
  img,
  appType,
  appName,
  description,
  padding,
  isDisabled,
  buttonText,
  buttonLink,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        pb: padding,
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "50%" }, order: { xs: 1, md: 2 } }}>
        <img src={img} style={{ width: "100%", objectFit: "cover" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: { xs: "100%", md: "50%" },
          order: { xs: 2, md: 1 },
          px: { xs: 2, md: "5em" },
          py: { xs: 2, md: 0 },
        }}
      >
        <Typography
          sx={{
            color: "#62824D",
            fontWeight: "600",
            fontSize: "12px",
            pb: "1.5em",
          }}
        >
          {appType}
        </Typography>
        <Divider
          sx={{
            borderColor: "#62824D",
            borderBottomWidth: 2,
            width: "100%",
            mb: "2em",
          }}
        />
        <Typography sx={{ fontWeight: "500", fontSize: "34px", pb: "0.5em" }}>
          {appName}
        </Typography>
        <Typography sx={{ fontSize: "16px", pb: "2em" }}>
          {description}
        </Typography>
        <Link to={buttonLink}>
          <Button
            disabled={isDisabled}
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              color: "#FBFBFB",
              backgroundColor: "#62824D",
              "&&:hover": {
                backgroundColor: "#62824DB2",
              },
              "&.Mui-disabled": {
                color: "#FBFBFB",
                background: "#B1B1B1",
              },
              p: "10px 15px",
            }}
          >
            {buttonText}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
