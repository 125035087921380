import createTheme from "@mui/material/styles/createTheme.js";

const lightPalette = createTheme({
  mode: "light",
  primary: {
    main: "#62824D",
  },
  secondary: {
    main: "#f50057",
  },
  action: {
    disabledBackground: "#B1B1B1",
    disabled: "#FBFBFB",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&& .MuiTouchRipple-child": {
            backgroundColor: "#62824D",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html {
          overflow-y: scroll;
        }
      `,
    },
  },
  typography: {
    fontFamily: ["Montserrat, sans-serif"],
  },
});

const darkPalette = createTheme({
  mode: "dark",
  primary: {
    main: "#90caf9",
  },
  secondary: {
    main: "#f48fb1",
  },
});

export default {
  lightPalette,
  darkPalette,
};
