import React from "react";
import {
  Box,
  Grid,
  GridList,
  GridListTile,
  Backdrop,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useState } from "react";
import Blm from "../../assets/graphic-imgs/blm.png";
import CLAMerch from "../../assets/graphic-imgs/cla-merch.png";
import CLATech from "../../assets/graphic-imgs/cla-tech.png";
import CLAIconsI from "../../assets/graphic-imgs/CLA1.png";
import CLAIconsII from "../../assets/graphic-imgs/CLA2.png";
import Data from "../../assets/graphic-imgs/data.png";
import Inclusify from "../../assets/graphic-imgs/inclusify.png";
import Orlando from "../../assets/graphic-imgs/orlando.png";
import Plants from "../../assets/graphic-imgs/plants.png";
import Sift from "../../assets/graphic-imgs/sift.png";
import Synergy from "../../assets/graphic-imgs/synergy.png";
import Varare from "../../assets/graphic-imgs/varare.png";

const images = [
  Sift,
  Data,
  Synergy,
  CLAIconsI,
  Varare,
  Plants,
  CLAMerch,
  Blm,
  CLAIconsII,
  Inclusify,
  Orlando,
  CLATech,
];

const StyledImg = styled("img")(({ theme }) => ({
  width: "100%",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const EnlargedImg = styled("img")(({ theme }) => ({
  width: "40%",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

export const Graphic = () => {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);

  const handleOpen = (imgSrc) => {
    setSelectedImg(imgSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        p: "150px 0px 50px",
      }}
    >
      <Grid container spacing={5} sx={{ maxWidth: "1190px" }}>
        {images.map((imgSrc, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <StyledImg
              src={imgSrc}
              alt="Graphic"
              onClick={() => handleOpen(imgSrc)}
            />
          </Grid>
        ))}
      </Grid>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#fff",
          alignItems: "flex-start",
          justifyContent: "center",
          pt: "5%",
        }}
      >
        <EnlargedImg src={selectedImg} alt="Enlarged Graphic" />
      </Backdrop>
    </Box>
  );
};
