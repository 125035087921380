import { Button, Typography, Box } from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ADBrand from "../assets/img/AD_Brand.png";
import useTheme from "@mui/material/styles/useTheme.js";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery.js";
import Drawer from "@mui/material/Drawer/Drawer.js";
import IconButton from "@mui/material/IconButton/IconButton.js";
import MenuIcon from "@mui/icons-material/Menu.js";
import { useState } from "react";

const links = [
  { text: "UX", url: "/" },
  { text: "GRAPHIC", url: "/graphic" },
  { text: "ABOUT", url: "/about" },
  { text: "CONTACT", url: "/contact" },
];

export const Navbar = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "center",
        backgroundColor: "#FBFBFB80",
        maxHeight: "100px",
        p: { xs: "33px 22px", md: "33px" },
        position: "fixed",
        top: "0",
        zIndex: "50",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "1440px",
          m: "auto",
        }}
      >
        {isSmallScreen ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "75%",
              }}
            >
              <img src={ADBrand} style={{ maxHeight: "30px", width: "100%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "25%",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img src={ADBrand} style={{ maxHeight: "30px" }} />
            </Box>
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {links.map((link) => (
                <NavLink to={link.url} style={{ textDecoration: "none" }}>
                  <Button>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textDecoration:
                          location.pathname === link.url ? "underline" : "none",
                        color:
                          location.pathname === link.url
                            ? "#62824D"
                            : "#292929",
                        "&:hover": {
                          color: "#62824DB2",
                        },
                      }}
                    >
                      {link.text}
                    </Typography>
                  </Button>
                </NavLink>
              ))}
            </Box>
          </>
        )}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{
            sx: { width: "50%", p: "50px 0px" },
          }}
        >
          {links.map((link) => (
            <Button onClick={handleDrawerClose} sx={{ m: "15px 0px" }}>
              <NavLink to={link.url} style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                    fontSize: "14px",
                    textDecoration:
                      location.pathname === link.url ? "underline" : "none",
                    color:
                      location.pathname === link.url ? "#62824D" : "#292929",
                    "&:hover": {
                      color: "#62824DB2",
                    },
                  }}
                >
                  {link.text}
                </Typography>
              </NavLink>
            </Button>
          ))}
        </Drawer>
      </Box>
    </Box>
  );
};
