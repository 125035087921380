import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { ImageSwiper } from "../ImageSwiper";
import TiltedPhone from "../../../assets/case-studies/Hoptimist/tilted-phone.png";
import CompAudit from "../../../assets/case-studies/Hoptimist/compaudit-hoptimist.png";
import Homepage from "../../../assets/case-studies/Hoptimist/HomePage.png";
import Cancel from "../../../assets/case-studies/Hoptimist/Cancel.png";
import Confirmation from "../../../assets/case-studies/Hoptimist/Confirmation.png";
import CreateAccount1 from "../../../assets/case-studies/Hoptimist/CreateAccount1.png";
import CreateAccount2 from "../../../assets/case-studies/Hoptimist/CreateAccount2.png";
import Events from "../../../assets/case-studies/Hoptimist/Events.png";
import ManageReservation from "../../../assets/case-studies/Hoptimist/ManageReservation.png";
import Reservation from "../../../assets/case-studies/Hoptimist/Reservation.png";
import ReservationDetails from "../../../assets/case-studies/Hoptimist/ReservationDetails.png";
import SeatingPreference from "../../../assets/case-studies/Hoptimist/SeatingPreference.png";
import Sitemap from "../../../assets/case-studies/Hoptimist/sitemap.jpg";
import Lucille from "../../../assets/case-studies/Hoptimist/Lucille.png";
import Sasha from "../../../assets/case-studies/Hoptimist/Sasha.png";
import MVPEvents from "../../../assets/case-studies/Hoptimist/MVP_EVENTS.png";
import MVPCreateAccount1 from "../../../assets/case-studies/Hoptimist/MVP_CREATE AN ACCOUNT-1.png";
import MVPCreateAccount2 from "../../../assets/case-studies/Hoptimist/MVP_CREATE AN ACCOUNT.png";
import MVPHomepage from "../../../assets/case-studies/Hoptimist/MVP_HOMEPAGE_ArianaDetrez.png";
import MVPReservationSeating from "../../../assets/case-studies/Hoptimist/MVP_RESERVATIONS SEATING PREFERENCE.png";
import MVPReservationTime from "../../../assets/case-studies/Hoptimist/MVP_RESERVATIONS TIME.png";
import Phone1 from "../../../assets/case-studies/Hoptimist/phone1.png";
import Phone2 from "../../../assets/case-studies/Hoptimist/phone2.png";
import Phone3 from "../../../assets/case-studies/Hoptimist/phone3.png";

const wireframeImages = [
  Homepage,
  Events,
  Confirmation,
  CreateAccount1,
  CreateAccount2,
  ReservationDetails,
  SeatingPreference,
  Cancel,
  ManageReservation,
  Reservation,
];

const highFidelityImages = [
  MVPEvents,
  MVPHomepage,
  MVPReservationSeating,
  MVPReservationTime,
  MVPCreateAccount1,
  MVPCreateAccount2,
];

export const Hoptimist = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1187px",
          maxHeight: { xs: "100%", md: "580px" },
          m: "150px 0px 50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "90%", sm: "60%" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "12px" },
              textAlign: "left",
              fontWeight: "600",
              color: "#62824D",
              p: "0em 0em 1em",
            }}
          >
            SCHEDULING APP FOR BEER GARDENS
          </Typography>
          <Divider
            sx={{
              borderColor: "#62824D",
              borderBottomWidth: 2,
              width: "100%",
              mb: "2em",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: "22", md: "34px" },
              fontWeight: "500",
              lineHeight: { xs: "21px", md: "41px" },
              p: { xs: "0px", md: "0px 0px 36px" },
            }}
          >
            There is no easy way to make a reservation for a garden beer  in the
            area or check out local events. The goal is to create a seamless way
            to make reservations for one’s local beer garden and to connect with
            their third community via local events.
          </Typography>
          <Typography
            sx={{
              display: { xs: "none", md: "block" },
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <b>Duration:</b> December 2022 — April 2024 <br /> <b>Role:</b> UX
            Researcher & Designer
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: "90%", sm: "40%" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <img
            src={TiltedPhone}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          <Typography
            sx={{
              display: { xs: "block", md: "none" },
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <b>Duration:</b> December 2022 — April 2024 <br /> <b>Role:</b> UX
            Researcher & Designer
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: { xs: "100%", md: "400px" },
          backgroundColor: "#EAEAEA",
          p: "4em 0em 4em",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            m: "0em 0em 1em",
          }}
        >
          DISCOVERY & INSPIRATION
        </Typography>
        <Divider
          sx={{
            borderColor: "#262626",
            borderBottomWidth: 2,
            width: { xs: "80%", md: "30%" },
            m: "0em 0em 2em",
          }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            maxWidth: "856px",
            width: { xs: "80%", md: "100%" },
            textAlign: "left",
          }}
        >
          During my time while living in Charlotte, NC (the city with the most
          breweries in North Carolina!) I felt like I couldn’t keep up with
          which breweries to visit, what events were happening and what types of
          beers I should try. This is what led to the concept of Hoptimist – an
          all-in-one app that allows me access to the local breweries to
          reserve, view events, and learn more about beer. The goal is to make
          it all less intimidating, easier to plan an evening out, and to
          discover the local breweries (bonus, while supporting local small
          business!).
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          p: { xs: "2em 0em 1em", md: "4em 0em 4em" },
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            m: "0em 0em 1em",
          }}
        >
          COMPETITIVE AUDIT
        </Typography>
        <Divider
          sx={{
            borderColor: "#262626",
            borderBottomWidth: 2,
            width: { xs: "80%", md: "30%" },
            m: "0em 0em 3em",
          }}
        />
        <Box sx={{ maxWidth: { xs: "90%", md: "80%" } }}>
          <img
            src={CompAudit}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            maxWidth: "856px",
            width: { xs: "90%", md: "100%" },
            textAlign: "center",
            m: { xs: "2em 0em 0em", md: "3em 0em 0em" },
          }}
        >
          Overall there are apps that allow users to make reservations to dine
          at restaurants however, there is no app niched down to beer gardens.
          Offering this specific service allows beer gardens to offer more niche
          information. Such as, what’s currently on tap and information on
          various beers.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          p: "4em 0em 4em",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            m: "0em 0em 1em",
          }}
        >
          PAPER WIREFRAME
        </Typography>
        <Divider
          sx={{
            borderColor: "#262626",
            borderBottomWidth: 2,
            width: { xs: "80%", md: "30%" },
            m: "0em 0em 3em",
          }}
        />
        <Box sx={{ display: { xs: "none", md: "block" }, maxWidth: "100%" }}>
          <Grid container spacing={5} sx={{ maxWidth: "1190px" }}>
            {wireframeImages.map((imgSrc, index) => (
              <Grid key={index} item xs={12} sm={6} md={2.4}>
                <img
                  src={imgSrc}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            width: { xs: "70%", sm: "50%" },
          }}
        >
          <ImageSwiper images={wireframeImages} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#EAEAEA",
          p: "5em 0em 5em",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            m: "0em 0em 1em",
          }}
        >
          UX QUESTIONS
        </Typography>
        <Divider
          sx={{
            borderColor: "#262626",
            borderBottomWidth: 2,
            width: { xs: "80%", md: "40%" },
            m: "0em 0em 2em",
          }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            maxWidth: { xs: "80%", md: "856px" },
            textAlign: "center",
          }}
        >
          How easy is it for users to find and navigate to the beer garden
          reservation feature within the app? <br />
          <br />
          What factors influence users' decisions to make a beer garden
          reservation through the app, rather than calling or visiting in
          person? <br />
          <br />
          How do users prefer to receive confirmation and reminders for their
          beer garden reservations? <br />
          <br />
          How do users feel about the process of selecting and reserving a
          specific table or area within the beer garden through the app? <br />
          <br />
          How satisfied are users with the overall experience of making a beer
          garden reservation through the app?
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1187px",
          maxHeight: { xs: "100%", md: "580px" },
          m: "50px 0px 50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "90%", md: "50%" },
            p: { xs: "1em", md: "3em" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "#262626",
              p: "0em 0em 1em",
            }}
          >
            SITE MAP
          </Typography>
          <Divider
            sx={{
              borderColor: "#262626",
              borderBottomWidth: 2,
              width: "100%",
              mb: "2em",
            }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Utilizing Figma, FigJam, I created a user flow to clearly see how
            the user will navigate through the app. This is simply the beginning
            of the app and there is still more to discover such as; in-app
            reminders, cross-functionality, and easily sharing reservations with
            friends.
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: "90%", md: "50%" },
            p: { xs: "1em", md: "3em" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <img
            src={Sitemap}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1187px",
          m: "0px 0px 25px",
        }}
      >
        <Box
          sx={{
            order: { xs: 2, md: 1 },
            maxWidth: { xs: "90%", md: "50%" },
            p: { xs: "1em", md: "3em" },
            m: { xs: "auto", md: "0px" },
          }}
        >
          <img src={Sasha} style={{ width: "100%", objectFit: "contain" }} />
          <img src={Lucille} style={{ width: "100%", objectFit: "contain" }} />
        </Box>
        <Box
          sx={{
            order: { xs: 1, md: 2 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "90%", md: "50%" },
            m: { xs: "auto", md: "0px" },
            p: { xs: "1em", md: "3em" },
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "#262626",
              p: "0em 0em 1em",
            }}
          >
            PERSONA STUDIES
          </Typography>
          <Divider
            sx={{
              borderColor: "#262626",
              borderBottomWidth: 2,
              width: "100%",
              mb: "2em",
            }}
          />
        </Box>
        <Box
          sx={{
            order: { xs: 3, md: 2 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "90%", md: "50%" },
            m: { xs: "auto", md: "0px" },
            p: { xs: "1em", md: "3em" },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              p: "0px 0px 36px",
            }}
          >
            <b>Sasha’s problem statement:</b> Sasha is a busy millennial
            freelance software engineer who enjoys going out in the evening with
            her friends who needs an easy way to reserve a table at her favorite
            beer garden because all of her friends have tight schedules but want
            to enjoy an evening out. <br />
            <br />
            <b>Lucille’s problem statement:</b> Lucille is a working
            professional and busy father who has a tough time carving out time
            to spend time with his friends. Him and his friends really enjoy
            attending events but have a hard time finding one’s local to them.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1187px",
          m: "50px 0px 50px",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            p: "0em 0em 1em",
          }}
        >
          HIGH-FIDELITY PROTOTYPE
        </Typography>
        <Box sx={{ maxWidth: "456px", width: "100%" }}>
          <Divider
            sx={{
              borderColor: "#262626",
              borderBottomWidth: 2,
              width: { xs: "80%", md: "100%" },
              m: "0em auto 2em",
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            width: { xs: "70%", sm: "50%" },
          }}
        >
          <ImageSwiper images={highFidelityImages} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          maxWidth: "1187px",
          maxHeight: { xs: "100%", md: "580px" },
          m: "50px 0px 50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "100%", md: "50%" },
            p: { xs: "1em", md: "3em" },
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "#262626",
              p: "0em 0em 1em",
            }}
          >
            ACCESSIBILITY CONSIDERATIONS
          </Typography>
          <Divider
            sx={{
              borderColor: "#262626",
              borderBottomWidth: 2,
              width: "100%",
              mb: "2em",
            }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              p: "0px 0px 36px",
            }}
          >
            <b>TEXT SIZE AND COLOR:</b>
            <br /> The color choice of the fonts were carefully selected and
            tested to be ADA complaint.
            <br />
            <br />
            <b>VOICE COMMANDS:</b>
            <br /> The mobile app will allow users to navigate and interact with
            the app using voice commands.
            <br />
            <br />
            <b>AUDIO DESCRIPTION:</b>
            <br /> The mobile app can provide audio descriptions of images,
            menus, and other features to assist users with visual impairments.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: { xs: "100%", md: "50%" },
            p: { xs: "0em 1em", md: "1em" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              maxWidth: "33%",
              height: "100%",
              m: "15px 0px 0px",
            }}
          >
            <img src={Phone1} style={{ width: "100%", objectFit: "contain" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              maxWidth: "33%",
              height: "100%",
              m: "-15px 0px 0px",
            }}
          >
            <img src={Phone2} style={{ width: "100%", objectFit: "contain" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              maxWidth: "33%",
              height: "100%",
              m: "15px 0px 0px",
            }}
          >
            <img src={Phone3} style={{ width: "100%", objectFit: "contain" }} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#EAEAEA",
          p: "5em 0em 5em",
          m: "0em 0em 2em",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#262626",
            m: "0em 0em 1em",
          }}
        >
          REFLECTIONS & NEXT STEPS
        </Typography>
        <Divider
          sx={{
            borderColor: "#262626",
            borderBottomWidth: 2,
            width: { xs: "80%", md: "30%" },
            m: "0em 0em 2em",
          }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            maxWidth: { xs: "80%", md: "856px" },
            textAlign: "left",
          }}
        >
          Creating this scheduling app, I was able to diver deeper in the user
          flow of what a user may need throughout the reservation process, what
          information they would like at hand, and deeper knowledge they may be
          seeking that could be offered to ensure they stay on the app.
          <br />
          <br />
          <b>Next steps:</b>
          <ul>
            <li>
              Continue to build out the entire MVP user flow via Figma
              prototyping.
            </li>
            <li>
              Create a way to order beer directly through the app as well once
              you are at the beer garden for a full user experience from start
              to reservation to checkout.
            </li>
          </ul>
        </Typography>
      </Box>
    </Box>
  );
};
